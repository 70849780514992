<template>
  <div>
     <div class="d-flex justify-content-start align-items-center mt-5" >
      <button
        class="btn btn-warning mr-1"
        v-b-tooltip.hover
        title="Download Excel"
        @click="btnExcelOnClick"
        v-if="printedData != null"
      >
        <i class="fas fa-file-excel px-0"></i>
      </button>
     
    </div>

    <!-- Table -->
    <b-table
      striped
      hover
      :items="dataModal"
      :fields="fields"
      responsive
    >
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          
          :style="{ width: field.key === 'actions' ? purpose == 'master' ? '17%' : '' : '', width: field.key == 'generic_name' ? '15%' : '' }"
        />
      </template>
      <template #cell(batch)="data">
        <span v-html="data.item.batch"></span>
      </template>
      <template #cell(sales_price)="data">
        {{ parseInt(data.item.sales_price).toLocaleString("id-ID") }}
      </template>
     
    </b-table>
   

    

  </div>
</template>

<script>
import XLSX from "xlsx";
import api from '@/core/services/api.service.js'
import module from "@/core/modules/CrudModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {

  props: {
    purpose: String,
    selectedItem: Array,
    checkbox: Boolean,
    isHospitalTransaction: Boolean,
    dataModal:Array,
    excel:Array,
  },
   components: {
    
    XLSX,
   
  },

  data() {
    return {
      // Pagination
      // perPage: 20,
      // currentPage: 1,
      // totalRows: 0,
      // Filter
      filter: {
        name: "",
        medicine_category_id: "",
        medicine_packages_id:"",
      },
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "generic_name",
          label: "Nama Umum",
          sortable: true,
        },
        {
          key: "medicine_category_name",
          label: "Kategori",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        {
          key: "medicine_remaining_stock",
          label: "Sisa Stok",
          sortable: true
        },
        {
          key: "Batch",
          label: "Batch",
          sortable: true
        },
        {
          key: "uom_name",
          label: "Satuan",
          sortable: true
        },
        {
          key: "sales_price",
          label: "Harga Jual",
          sortable: true,
          thClass: "nowrap",
          tdClass: "nowrap"
        },
        // {
        //   key: "is_ecommerce_product",
        //   label: "E-commerce",
        //   sortable: true,
        //   thClass: "nowrap",
        //   tdClass: "nowrap"
        // },
        // {
        //   key: "actions",
        //   label: "Aksi",
        //   thClass: "nowrap",
        //   tdClass: "nowrap"
        // },
      ],
      // Table Items
      items: [],
      medicine_categories: [],
      medicine_packages: [],
      total_medicine: '',
      default_total_medicine: '',
      medicineItem:[],
      excelData:[],
      printedData:[],
      formats: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "20", value: 20 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
        { text: "300", value: 300 },
      ],
    }
  },

  methods: {
    btnExcelOnClick() {
        const data = XLSX.utils.json_to_sheet(this.excel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, "Daftar Obat dan Alkes.xlsx");
  
        this.$bvModal.hide("modal-medicine-report");
      
    },

    pageOnClick(evt, page) {
      evt.preventDefault()
      this.currentPage = page
      this.pagination()
    },

    filterByName() {
      this.pagination()
    },

    async filterByMedicineCategory(evt) {
      
      if (!evt) {
        this.filter.medicine_category_id = await ''
        this.pagination()
      } else {
        this.filter.medicine_category_id = await evt
        this.pagination()
        
      }
    },

    async pagination() {
      // let route = "medicines"
      
      let filterParams = `&name=${this.filter.name}&medicine_category_id=${this.filter.medicine_category_id}`
      let response = await module.get(
          "medicine-no-paginations",
          `?${filterParams}`
        )
     
      let responseDatas = await module.get(
        "medicines-excel",
        `?${filterParams}`
      )

      this.items = response
      // this.excelData = await responseDatas
      // this.excelData = this.excel
      
      // Coloring
      let a
      for (a = 0; a < this.items.length; a++) {
        
        if (this.items[a].medicine_remaining_stock < this.items[a].minimum_stock && this.items[a].medicine_remaining_stock != 0) {
          //  data[a].number = a + 1
          this.items[a]._rowVariant = 'warning'
          
        } else if (this.items[a].medicine_remaining_stock == 0) {
          this.items[a]._rowVariant = 'danger'
          
        }
      }
      // this.getBatch()
    },

    

  },


  mounted() {
    
    this.pagination()
  },

}

</script>

<style>
.th-list-medicines {
  vertical-align: middle !important;
}

.td-list-medicines {
  vertical-align: middle !important;
}

.nowrap{
  white-space: nowrap;
}
</style>