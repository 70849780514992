<template>
  <div>
      <table class="mt-2">
        <tr>
          <th>Obat</th>
          <th>Dosis</th>
          <th>Frekuensi</th>
          <th>Hari</th>
          <th>Instruksi</th>
        </tr>
        <tr>
          <td><input type="text" class="form-control form-control-sm" style="border: solid 0.5px black" value="nama obat"></td>
          <td><input type="text" class="form-control form-control-sm" v-model="medicine_tes" style="border: solid 0.5px black" placeholder="100 mg"></td>
          <td><input type="text" class="form-control form-control-sm" ref="dosis" name="dosis" style="border: solid 0.5px black" placeholder="1 + 0 + 1"></td>
          <td><input type="text" class="form-control form-control-sm" style="border: solid 0.5px black" placeholder="7 Hari"></td>
          <td><input type="text" class="form-control form-control-sm" style="border: solid 0.5px black" placeholder="Setelah Makan"></td>
        </tr>
      </table>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>