<template>
  <div>
    <v-app>
      <!-- <v-date-picker v-model="picker"></v-date-picker> -->

      <b-form @submit.stop.prevent="formOnsubmit">
        <!-- Input Modal Trigger -->
        <b-form-group
          id="input-group-trigger-modal-patient"
          label="Pasien:"
          label-for="input-trigger-modal-patient"
        >
          <b-form-input
            id="input-trigger-modal-patient"
            v-model="display.patient_name"
            placeholder="Pilih Pasien"
            readonly
            @click="$bvModal.show('modal-patient')"
          >
          </b-form-input>
          <small class="text-danger">{{ error.patient_id }}</small>
        </b-form-group>

        <!-- sub patient Select -->
        <b-form-group
          v-if="subPatient.length > 1 "
          id="input-group-sub-patient"
          label="Pilih Sub Pasien:"
          label-for="input-sub-patient"
        >
          <treeselect
            v-model="form.sub_patient_id"
            placeholder="Pilih Sub Pasien"
            :options="subPatient"
          />
          <small class="text-danger">{{ error.sub_patient_id }}</small>
        </b-form-group>

        <!-- <b-form-group
        id="input-group-trigger-modal-patient"
        label="Pasien:"
        label-for="input-trigger-modal-patient"
      >
        <v-dialog
        ref="dialog"
        v-model="modal2"
        :return-value.sync="time"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="time"
            label="Picker in dialog"
            prepend-icon="mdi-clock-time-four-outline"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>

        <v-date-picker v-if="modal2" v-model="picker"> <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(picker)"
          >
            OK
          </v-btn></v-date-picker>
      </v-dialog>
        <small class="text-danger">{{ error.patient_id }}</small>
        <small class="text-danger">{{ picker }}</small>
      </b-form-group> -->

        <!-- Input Date -->
        <!-- <b-form-group
          id="input-group-date"
          label="Tanggal:"
          label-for="input-date"
        >
          <v-dialog
            ref="dialog"
            v-model="modal2"
            :return-value.sync="form.date"
            persistent
            width="350px"
          >
            <template v-slot:activator="{ on, attrs }">
              <b-form-input
                readonly
                v-bind="attrs"
                v-on="on"
                id="input-trigger-modal-patient"
                v-model="form.date"
              >
              </b-form-input>
            </template>

            <v-date-picker
              v-if="modal2"
              locale="id"
              v-model="form.date"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal2 = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(form.date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group> -->
        <b-form-group
          id="input-group-birth-date"
          label="Tanggal :"
          label-for="input-sip-birth-date"
        >
          <b-input-group>
            <div class="row">
              <div class="col-md-12">
                <v-dialog
                  ref="dialog"
                  v-model="modal2"
                  :return-value.sync="form.date"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <b-input-group>
                      <b-input-group-prepend>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <i class="fas fa-calendar"></i>
                        </button>
                      </b-input-group-prepend>
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="form.date"
                        placeholder="(YYYY-MM-DD)"
                        @input="formaterDate"
                      >
                      </b-form-input>
                    </b-input-group>
                  </template>

                  <v-date-picker v-if="modal2" v-model="form.date" locale="id">
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal2 = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(form.date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </div>
            </div>
          </b-input-group>

          <small class="text-danger">{{ error.date }}</small>
        </b-form-group>

        <!-- Input Doctor -->
        <b-form-group
          v-if="currentUser.user_type_id != 1"
          id="input-group-doctor"
          label="Dokter:"
          label-for="input-doctor"
        >
          <treeselect
            v-model="form.doctor_id"
            :multiple="false"
            placeholder="Pilih Dokter"
            :options="doctors"
          />
          <small class="text-danger">{{ error.doctor_id }}</small>
        </b-form-group>

        <!-- Input redeem type -->
        <b-form-group
          id="input-group-redeem"
          label="Jenis Penebusan :"
          label-for="input-redeem"
        >
          <treeselect
            v-model="form.redeem_type"
            :multiple="false"
            placeholder="Pilih Jenis Penebusan"
            :options="redeemTypeShorted"
          />
          <small class="text-danger">{{ error.redeem_type }}</small>
        </b-form-group>

        <!-- Input History -->
        <b-form-group id="input-group-history">
          <label for="input-history"
            >Riwayat: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-history"
            v-model="form.history"
            rows="3"
            placeholder="Riwayat"
          ></b-form-textarea>
          <small class="text-danger">{{ error.history }}</small>
        </b-form-group>

        <!-- Input Advice -->
        <b-form-group id="input-group-advice">
          <label for="input-advice"
            >Saran: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-advice"
            v-model="form.advice"
            rows="3"
            placeholder="Saran"
          ></b-form-textarea>
          <small class="text-danger">{{ error.advice }}</small>
        </b-form-group>

        <!-- Input Notes -->
        <b-form-group id="input-group-notes">
          <label for="input-notes"
            >Catatan: <em class="text-muted">opsional</em></label
          >
          <b-form-textarea
            id="input-notes"
            v-model="form.notes"
            rows="3"
            placeholder="Catatan"
          ></b-form-textarea>
          <small class="text-danger">{{ error.notes }}</small>
        </b-form-group>

        <!-- Input Medicine -->
        <!-- <b-form-group
          id="input-group-medicine"
          label="Obat:"
          label-for="input-medicine"
        >
          <treeselect
            v-model="medicine_id"
            :multiple="true"
            :options="medicines"
            placeholder="Pilih Obat"
            @select="setPrescription"
            @deselect="removeMedicine"
          />
          <small class="text-danger">{{ error.medicine }}</small>
        </b-form-group> -->

        <!-- Input Modal Trigger -->
        <b-form-group id="input-group-trigger-modal-medicine">
          <label for="input-trigger-modal-medicine">Obat:</label>
          <b-input-group>
            <b-form-input
              id="input-trigger-modal-medicine"
              v-model="display.medicine_name"
              placeholder="Pilih Obat"
              readonly
              @click="$bvModal.show('modal-medicine')"
              style="border-right: none"
            >
            </b-form-input>
            <template #append>
              <b-input-group-text
                squared
                :class="btnClearMedicineClass"
                style="border-left: none"
                @click="resetMedicine"
                @mouseover="btnClearMedicineClass = 'bg-light-danger'"
                @mouseout="btnClearMedicineClass = 'bg-white'"
              >
                <i class="fas fa-sm fa-times text-danger"></i>
              </b-input-group-text>
            </template>
          </b-input-group>
          <small class="text-danger">{{ error.medicines }}</small>
        </b-form-group>

        <!-- <b-table hover :items="medicine_collection" :fields="fields">
        <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
          />
        </template>
      </b-table> -->

        <div class="bg-light" v-if="medicine_collection.length > 0">
          <table class="table">
            <tr>
              <th>Nama</th>
              <th>Dosis</th>
              <th>Frekuensi</th>
              <th>Hari</th>
              <th>Instruksi</th>
            </tr>
            <tr v-for="mdc in medicine_collection" :key="mdc.id">
              <td>{{ mdc.name }}</td>
              <td>{{ mdc.dosage }}</td>
              <td>{{ mdc.frequency }}</td>
              <td>{{ mdc.days }}</td>
              <td>{{ mdc.instruction }}</td>
            </tr>
          </table>
        </div>

        <b-modal
          ref="my-modal"
          hide-footer
          title="Resep Obat"
          @hide="hideModalCancel"
        >
          <b-form @submit.stop.prevent="medicineFormOnsubmit">
            <!-- <div class="d-block text-center"> -->
            <b-form-group
              id="input-group-name"
              label="Nama:"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="medicine_collection_form.name"
                placeholder="Nama obat"
                readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-dosage"
              label="Dosis:"
              label-for="input-dosage"
            >
              <b-form-input
                id="input-dosage"
                v-model="medicine_collection_form.dosage"
                placeholder="100 mg"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-frequency"
              label="Frekuensi:"
              label-for="input-frequency"
            >
              <b-form-input
                id="input-frequency"
                v-model="medicine_collection_form.frequency"
                placeholder="1+0+1"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-days"
              label="Hari:"
              label-for="input-days"
            >
              <b-form-input
                id="input-days"
                v-model="medicine_collection_form.days"
                placeholder="7 hari"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-instruction"
              label="Instruksi:"
              label-for="input-instruction"
            >
              <b-form-input
                id="input-instruction"
                v-model="medicine_collection_form.instruction"
                placeholder="Setelah makan"
                required
              ></b-form-input>
            </b-form-group>
            <!-- </div> -->

            <div class="row p-1">
              <div class="col-md-12">
                <span
                  ><i class="fas fa-info-circle text-info"></i> Catatan :</span
                >
                <ul style="list-style-type: none">
                  <li>
                    Penulisan frekuensi obat dituliskan dengan format
                    <b
                      >dosis1 + dosis2 + dosis3 dan seterusnya tanpa spasi
                      (misal : 1+1+1)</b
                    >
                  </li>
                </ul>
              </div>
            </div>
            <!-- Submit & Cancel button -->
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="hideModalCancel"
            >
              Cancel
            </b-button>
          </b-form>
        </b-modal>

        <!-- <div v-html="form.medicine"></div>
      <div v-text="form.medicine"></div> -->

        <!-- Button -->
        <b-button type="submit" variant="primary">Simpan</b-button>
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="$router.push('/prescriptions/list')"
        >
          Batal
        </b-button>
      </b-form>

      <ModalPatient
        :selectedDoctor="form.doctor_id.toString()"
        @chosenPatient="setPatient"
        @submitedPatient="setPatient"
      />
      <ModalMedicine
        @chosenMedicine="setPrescription"
        :selectedItem="medicine_collection"
        :checkbox="true"
        :isSortcut="false"
        :isAbjad="true"
      />
    </v-app>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import ModalPatient from "@/component/general/ModalPatient.vue";
import Medicine from "@/component/prescriptions/Medicine.vue";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service.js";
import ModalMedicine from "@/component/general/ModalMedicine.vue";
import { getUser } from "@/core/services/jwt.service.js";

export default {
  name: "Add",

  props: {
    form: Object,
    route: String,
  },

  components: {
    Card,
    Medicine,
    ModalPatient,
    ModalMedicine,
  },

  data() {
    return {
      // Display Data
      display: {
        patient_name: this.$route.query.patient_name ?? "",
        medicine_name: "",
      },
      time: null,
      menu2: false,
      modal2: false,
      picker: new Date().toISOString().substr(0, 10),
      doctors: [],
      medicines: [],
      medicine_id: [],
      medicineId: [],
      medicine_tes: `<table style="border-collapse: collapse; width: 100%;" border="1"> <tbody> <tr> <td style="width: 50%;">obat</td> <td style="width: 50%;">dosis</td> </tr> <tr> <td style="width: 50%;">sanmol</td> <td style="width: 50%;">100</td> </tr>`,
      redeemType: [
        { label: "Internal (Pembayaran)", id: 0 },
        { label: "Internal (Apotek)", id: 1 },
        { label: "Eksternal", id: 2 },
      ],
      redeemTypeShorted: [],
      // Error
      error: {
        date: "",
        patient_id: "",
        patient_name: "",
        doctor_id: "",
        doctor_name: "",
        history: "",
        notes: "",
        advice: "",
        medicine: "",
        redeem_type: "",
      },
      medicine: "",
      name_medicine: "",
      dosage: [],
      frequency: [],
      days: [],
      intructions: [],
      medicine_collection: [],
      medicine_collection_str: [],
      medicine_collection_form: {
        id: "",
        name: "",
        dosage: "",
        frequency: "",
        days: "",
        instruction: "",
      },
      medicine_collection_submit: false,
      // current user
      currentUser: getUser(),
      // other
      btnClearMedicineClass: "bg-white",
      subPatient: [],
    };
  },

  methods: {
    setPatient(value) {
      this.subPatient = []
      this.form.sub_patient_id = ""

      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.getSubPatientOption();
      this.$bvModal.hide("modal-patient");
    },

    async getSubPatientOption(type) {
      let route = "sub-patients/" + this.form.patient_id;

      let response = await module.setTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.subPatient = response.data;
        this.subPatient.unshift({
          label: "Pilih Sub Pasien",
          id: "",
          isDisabled: true,
        });
      }
    },

    formaterDate(val) {
      if (val.length == 4) {
        this.form.date = this.form.date + "-";
      }
      if (val.length == 7) {
        this.form.date = this.form.date + "-";
      }
    },

    onReady() {
      // Insert the toolbar before the editable area.
      //   editor.ui
      //     .getEditableElement()
      //     .parentElement.insertBefore(
      //       editor.ui.view.toolbar.element,
      //       editor.ui.getEditableElement()
      //     );
    },

    hideModal() {
      this.$refs["my-modal"].hide();
    },

    async hideModalCancel() {
      if (this.medicine_collection_submit == false) {
        // this.medicine_id.pop();
      }
      await this.$refs["my-modal"].hide();
      this.setMedicineSubmit();
    },

    setMedicineSubmit() {
      this.medicine_collection_submit = false;
    },

    async setPrescription(value) {
      if (this.medicine_collection.length > 0) {
        let a;
        for (a = 0; a < this.medicine_collection.length; a++) {
          if (this.medicine_collection[a].id == value.id) {
            this.medicine_collection.splice(a, 1);
            if (this.medicine_collection.length == 0) {
              this.display.medicine_name = "";
              break;
            } else if (this.medicine_collection.length > 0) {
              this.displayMedicineName(this.medicine_collection);
              break;
            }
            break;
          } else if (a == this.medicine_collection.length - 1) {
            this.$bvModal.hide("modal-medicine");
            await this.medicine_id;
            this.medicine_id = value.id;
            // this.medicine_collection_form.id = "";
            // this.medicine_collection_form.name = "";
            this.medicine_collection_form.dosage = "";
            this.medicine_collection_form.frequency = "";
            this.medicine_collection_form.days = "";
            this.medicine_collection_form.instruction = "";
            this.setMedicine(value.id);
          }
        }
      } else {
        this.$bvModal.hide("modal-medicine");
        await this.medicine_id;
        this.medicine_id = value.id;
        // this.medicine_collection_form.id = "";
        // this.medicine_collection_form.name = "";
        this.medicine_collection_form.dosage = "";
        this.medicine_collection_form.frequency = "";
        this.medicine_collection_form.days = "";
        this.medicine_collection_form.instruction = "";
        this.setMedicine(value.id);
      }
    },

    setMedicine(id) {
      ApiService.get("medicines/" + id).then((response) => {
        this.medicine = response.data.data;
        this.name_medicine = response.data.data.name;
        this.medicine_collection_form.name = response.data.data.name;
        this.medicine_collection_form.id = response.data.data.id;
        this.$refs["my-modal"].show();
      });
    },

    displayMedicineName(medicine) {
      if (medicine.length == 0) {
        this.display.medicine_name = "";
      } else {
        let str = "";
        medicine.forEach(function (value, index) {
          str += value.name;
          if (index != medicine.length - 1) {
            if (medicine.length > 1) str += ", ";
          }
        });
        this.display.medicine_name = str;
      }
    },

    resetMedicine() {
      this.medicine_collection.splice(0, this.medicine_collection.length);
      this.display.medicine_name = "";
    },

    removeMedicine(event) {

      // var arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

      // for (var i = 0; i < arr.length; i++) {
      //   if (arr[i] === 5) {
      //     arr.splice(i, 1);
      //   }
      // }

      let a;
      for (a = 0; a < this.medicine_collection.length; a++) {
        if (event.id == this.medicine_collection[a].id) {
          this.medicine_collection.splice(a, 1);
        }
      }
    },

    async list() {
      this.items = await module.list("medicines");
      this.dataLoaded = true;
    },

    async getDoctorOption() {
      let route;
      if (
        typeof this.form.patient_id !== "undefined" &&
        this.form.patient_id != ""
      ) {
        route = `doctors/get-by-patient/${this.form.patient_id}`;
      } else {
        route = "doctors";
      }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
        this.doctors.unshift({
          label: "Pilih Dokter",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getMedicineOption() {
      let response = await module.setTreeSelect("medicines");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.medicines = response.data;
        this.medicines.unshift({
          label: "Pilih Obat",
          id: "",
          isDisabled: true,
        });
      }
    },

    async getSettings() {
      let response = await module.get("settings/1");
      // Success
      if (response.prescription_status_active != null) {
        let data = response.prescription_status_active;
        data.forEach((el) => {
          let exist = this.redeemType.findIndex((x) => x.id == el);

          if (exist > -1) {
            this.redeemTypeShorted.push(this.redeemType[exist]);
          }
        });
      }
    },

    medicineFormOnsubmit() {
      this.medicine_collection_submit = true;
      // console.log("yang akan di input", this.medicine_collection_form);
      // this.medicine_collection.push(this.medicine_collection_form);
      let clone = { ...this.medicine_collection_form };
      this.medicine_collection.push(clone);

      // console.log("hasil", this.medicine_collection);
      this.displayMedicineName(this.medicine_collection);
      this.hideModal();
    },

    async formOnsubmit() {
      this.medicine_collection_str = await JSON.stringify(
        this.medicine_collection
      );
      this.form.medicine = await this.medicine_collection_str;
      let response = await module.submit(this.form, this.route);

      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/prescriptions/list");
      }
    },
  },

  mounted() {
    this.getDoctorOption();
    if (this.currentUser.user_type_id == 1) {
      this.form.doctor_id = this.currentUser.id;
    }
    this.getMedicineOption();
    this.getSettings();
  },

  watch: {
    "form.patient_id": function (newVal) {
      if (this.currentUser.user_type_id != 1) {
        this.form.doctor_id = "";
        this.getDoctorOption();
      }
    },
    "form.patient_name": function (newVal, oldVal) {
      this.display.patient_name = newVal;
    },
    "form.doctor_id": function (newVal) {
      if (typeof newVal === "undefined") this.form.doctor_id = "";
    },
  },
};
</script>

